import { Microfrontend } from '@thoughtspot/microfrontends';
import React from 'react';
import { loadMicroFrontendComponent } from './microfrontend-wrapper';
import { initMicroFrontend } from './microfrontends.util';

enum EurekaComponents {
    EurekaPage = 'EurekaPage',
    EurekaMainSearchBar = 'EurekaMainSearchBar',
    CreateAiAnswerPage = 'CreateAiAnswerPage',
    CreateAiAnswerSearchBar = 'CreateAiAnswerSearchBar',
    DataPanelTopLiveboards = 'DataPanelTopLiveboards',
    DataPanelTopAnswers = 'DataPanelTopAnswers',
    EurekaNlpFixerFeedbackContainer = 'EurekaNlpFixerFeedbackContainer',
    NLInterpretationsContainer = 'NLInterpretationsContainer',
    NlsFeedbackContainer = 'NlsFeedbackContainer',
    NlsFeedbackLeaderboardContainer = 'NlsFeedbackLeaderboardContainer',
    NLSFeedbackConfirmationModalContainer = 'NLSFeedbackConfirmationModalContainer',
    EurekaAiAnswerTitleDescriptionContainer = 'EurekaAiAnswerTitleDescriptionContainer',
    NlsAnswerSageTokenContainer = 'NlsAnswerSageTokenContainer',
    NlsFewshotExampleContainer = 'NlsFewshotExampleContainer',
}

export const NLS_FEEDBACK_LEADERBOARD_COUNT = 3;

export const EurekaPage = loadMicroFrontendComponent(
    Microfrontend.EUREKA,
    EurekaComponents.EurekaPage,
);
export const EurekaMainSearchBar = loadMicroFrontendComponent(
    Microfrontend.EUREKA,
    EurekaComponents.EurekaMainSearchBar,
);

export const DataPanelTopAnswers = loadMicroFrontendComponent(
    Microfrontend.EUREKA,
    EurekaComponents.DataPanelTopAnswers,
);

export const DataPanelTopLiveboards = loadMicroFrontendComponent(
    Microfrontend.EUREKA,
    EurekaComponents.DataPanelTopLiveboards,
);

export const EurekaNlpFeedbackContainer = loadMicroFrontendComponent(
    Microfrontend.EUREKA,
    EurekaComponents.EurekaNlpFixerFeedbackContainer,
);

export const NLInterpretationsContainer = loadMicroFrontendComponent(
    Microfrontend.EUREKA,
    EurekaComponents.NLInterpretationsContainer,
);

export const NlsFeedbackLeaderboardContainer = loadMicroFrontendComponent(
    Microfrontend.EUREKA,
    EurekaComponents.NlsFeedbackLeaderboardContainer,
);

export const NlsFeedbackContainer = loadMicroFrontendComponent(
    Microfrontend.EUREKA,
    EurekaComponents.NlsFeedbackContainer,
);

export const NlsFeedbackConfirmationModalContainer = loadMicroFrontendComponent(
    Microfrontend.EUREKA,
    EurekaComponents.NLSFeedbackConfirmationModalContainer,
);

export const EurekaAiAnswerTitleDescriptionContainer = loadMicroFrontendComponent(
    Microfrontend.EUREKA,
    EurekaComponents.EurekaAiAnswerTitleDescriptionContainer,
);

export const CreateAiAnswerPage = loadMicroFrontendComponent(
    Microfrontend.EUREKA,
    EurekaComponents.CreateAiAnswerPage,
);

export const CreateAiAnswerSearchBar = loadMicroFrontendComponent(
    Microfrontend.EUREKA,
    EurekaComponents.CreateAiAnswerSearchBar,
);

export const NlsAnswerSageTokenContainer = loadMicroFrontendComponent(
    Microfrontend.EUREKA,
    EurekaComponents.NlsAnswerSageTokenContainer,
);

export const NlsFewshotExampleContainer = loadMicroFrontendComponent(
    Microfrontend.EUREKA,
    EurekaComponents.NlsFewshotExampleContainer,
);

initMicroFrontend(Microfrontend.EUREKA);
